body {
    margin: 0
}

a.pointer {
    cursor: pointer;
}

.green-bg {
    background-color: #57bf00
}

.white-bg {
    background-color: #ffffff
}

.white {
    color: white !important
}

.login-container {
    max-width: 400px;
    width: 100%
}

.rs-table-cell-content button {
    margin-top: -7px
}

.num-tag {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, .1);
    width: 19px;
    height: 19px;
    display: inline-block;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    /* box-shadow: 0px 0px 5px 1px rgba(0,0,0,.3) */
}

.rs-input[disabled] {
    color: rgba(0, 0, 0, 0.8) !important;
}

.hide {
    display: none
}

.round {
    border-radius: 8px
}

.navbar-brand {
    height: 60%;
    margin-left: 10px
}

.login-brand {
    height: 60px;
    margin: 0 auto;
    display: block
}

.vertical-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.horizontal-center {
    display: flex !important;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center
}

.full-width {
    display: block;
    width: 100%;
    height: 1000px;
}

.siteContent {
    margin: 0 !important;
}

.rs-picker-toggle-clean {
    cursor: pointer;
}

.rs-navbar {
    box-shadow: 0px 5px 5px rgba(0, 0, 0, .1);
    z-index: 999;
    display: block;
    position: fixed;
    width: 100%;
}

.rs-table-cell-content .rs-tag.rs-tag-default.last {
    width: 14%;
    text-align: center;
    position: absolute;
    left: 12px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0);
}

.rs-table-cell-content .rs-tag.rs-tag-default {
    width: 100%;
    text-align: center;
}

.siteContent .sidebar,
.siteContent .content {
    padding-top: 56px
}

.rs-navbar-header {
    line-height: 56px;
    padding-left: 10px;
    padding-right: 10px
}

.rs-navbar-header h3 {
    display: inline;
    position: relative;
    left: 5px;
    vertical-align: middle;
    font-family: 'Quicksand', sans-serif
}

.rs-sidenav-collapse-in {
    width: 250px;
}

.rs-sidenav-body {
    /* border-right: 1px solid #19191966; */
}

.sidebar,
.rs-sidenav-body {
    height: 100%;
}

.sidebar {
    float: left;
    max-width: 250px;
    /* position: fixed;
    top: 56px;
    left: 0;
    bottom: 0;
    z-index: 116;
    backface-visibility: hidden;
    background-color: #fff; */
}

.content {
    /* overflow: auto; */
    min-height: 100%;
    /* padding: 15px; */
    border-left: 1px solid #19191966;
    margin-right: 15px;
    display: flex
}

.rs-divider-horizontal {
    margin-top: 0;
}

.table-button {
    margin-top: -6.5px
}

.padding-left {
    padding-left: 15px
}

.padding-right {
    padding-right: 15px
}

iframe {
    width: 100%;
    overflow: hidden;
}

.more-spacing h4 {
    line-height: 1.8rem
}

/* simple - enter transition 300ms, exit 150ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
    transform: scale(0.9);
}

.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 300ms ease 300ms;
}

.loading-screen {
    height: 100vh;
    width: 100%;
    background-color: #57bf00;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    transition: all 1s ease;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
}

.fade-exit {
    opacity: 1;
    transform: scale(0.9);
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 100ms ease;
}

body,
* {
    font-family: 'Nunito', sans-serif
}

html,
body {
    height: 100%;
    margin: 0;
}

#root,
.App,
.rs-row,
.rs-sidenav,
.rs-nav-vertical {
    height: 100%
}

h2 {
    font-weight: bold;
}